import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    showLogTable()
    hideCancelQueryButton()
  }

  query() {
    showLoadingScreen()
  }

  error() {
    showLogTable()
    showAlert("Kļūda datu atlasē!")
  }

  result(event) {
    const data = event.detail[0].body.innerHTML
    $('#log-table').html(data)
    showLogTable()
    showCancelQueryButton()
    return
  }
}

function showLoadingScreen() { 
  $('#log-table').hide()
  $('#loading-spinner').show()
}

function showLogTable() {
  $('#loading-spinner').hide()
  $('#log-table').show()
}

function hideCancelQueryButton() {
  $('#clear-query-button').hide()
}

function showCancelQueryButton() {
  $('#clear-query-button').show()
}