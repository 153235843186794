function showLoadingScreen(text="Lūdzu uzgaidi...") {
  $('#loading-text').html(text)
  $('#loading-overlay').attr('hidden', false)
}

function hideLoadingScreen() {
  $('#loading-text').html('Lūdzu uzgaidi...')
  $('#loading-overlay').attr('hidden', true)
}

export {showLoadingScreen, hideLoadingScreen}