I18n.translations || (I18n.translations = {});
I18n.translations["ru"] = I18n.extend((I18n.translations["ru"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Возникли ошибки: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Невозможно удалить запись, так как существуют зависимости: %{record}",
          "has_one": "Невозможно удалить запись, так как существует зависимость: %{record}"
        }
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Вс",
      "Пн",
      "Вт",
      "Ср",
      "Чт",
      "Пт",
      "Сб"
    ],
    "abbr_month_names": [
      null,
      "янв.",
      "февр.",
      "марта",
      "апр.",
      "мая",
      "июня",
      "июля",
      "авг.",
      "сент.",
      "окт.",
      "нояб.",
      "дек."
    ],
    "day_names": [
      "воскресенье",
      "понедельник",
      "вторник",
      "среда",
      "четверг",
      "пятница",
      "суббота"
    ],
    "formats": {
      "default": "%d.%m.%Y",
      "long": "%-d %B %Y",
      "short": "%-d %b"
    },
    "month_names": [
      null,
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря"
    ],
    "order": [
      "day",
      "month",
      "year"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "few": "около %{count} часов",
        "many": "около %{count} часов",
        "one": "около %{count} часа",
        "other": "около %{count} часов"
      },
      "about_x_months": {
        "few": "около %{count} месяцев",
        "many": "около %{count} месяцев",
        "one": "около %{count} месяца",
        "other": "около %{count} месяцев"
      },
      "about_x_years": {
        "few": "около %{count} лет",
        "many": "около %{count} лет",
        "one": "около %{count} года",
        "other": "около %{count} лет"
      },
      "almost_x_years": {
        "few": "почти %{count} года",
        "many": "почти %{count} лет",
        "one": "почти %{count} год",
        "other": "почти %{count} лет"
      },
      "half_a_minute": "полминуты",
      "less_than_x_minutes": {
        "few": "меньше %{count} минут",
        "many": "меньше %{count} минут",
        "one": "меньше %{count} минуты",
        "other": "меньше %{count} минут"
      },
      "less_than_x_seconds": {
        "few": "меньше %{count} секунд",
        "many": "меньше %{count} секунд",
        "one": "меньше %{count} секунды",
        "other": "меньше %{count} секунд"
      },
      "over_x_years": {
        "few": "больше %{count} лет",
        "many": "больше %{count} лет",
        "one": "больше %{count} года",
        "other": "больше %{count} лет"
      },
      "x_days": {
        "few": "%{count} дня",
        "many": "%{count} дней",
        "one": "%{count} день",
        "other": "%{count} дней"
      },
      "x_minutes": {
        "few": "%{count} минуты",
        "many": "%{count} минут",
        "one": "%{count} минута",
        "other": "%{count} минут"
      },
      "x_months": {
        "few": "%{count} месяца",
        "many": "%{count} месяцев",
        "one": "%{count} месяц",
        "other": "%{count} месяцев"
      },
      "x_seconds": {
        "few": "%{count} секунды",
        "many": "%{count} секунд",
        "one": "%{count} секунда",
        "other": "%{count} секунд"
      },
      "x_years": {
        "few": "%{count} года",
        "many": "%{count} лет",
        "one": "%{count} год",
        "other": "%{count} лет"
      }
    },
    "prompts": {
      "day": "День",
      "hour": "Час",
      "minute": "Минута",
      "month": "Месяц",
      "second": "Секунда",
      "year": "Год"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "нужно подтвердить",
      "blank": "не может быть пустым",
      "confirmation": "не совпадает со значением поля %{attribute}",
      "empty": "не может быть пустым",
      "equal_to": "может иметь лишь значение, равное %{count}",
      "even": "может иметь лишь четное значение",
      "exclusion": "имеет зарезервированное значение",
      "greater_than": "может иметь значение большее %{count}",
      "greater_than_or_equal_to": "может иметь значение большее или равное %{count}",
      "in": "должно быть в диапазоне %{count}",
      "inclusion": "имеет непредусмотренное значение",
      "invalid": "имеет неверное значение",
      "less_than": "может иметь значение меньшее чем %{count}",
      "less_than_or_equal_to": "может иметь значение меньшее или равное %{count}",
      "model_invalid": "Возникли ошибки: %{errors}",
      "not_a_number": "не является числом",
      "not_an_integer": "не является целым числом",
      "odd": "может иметь лишь нечетное значение",
      "other_than": "должно отличаться от %{count}",
      "present": "нужно оставить пустым",
      "required": "не может отсутствовать",
      "taken": "уже существует",
      "too_long": {
        "few": "слишком большой длины (не может быть больше чем %{count} символа)",
        "many": "слишком большой длины (не может быть больше чем %{count} символов)",
        "one": "слишком большой длины (не может быть больше чем %{count} символ)",
        "other": "слишком большой длины (не может быть больше чем %{count} символа)"
      },
      "too_short": {
        "few": "недостаточной длины (не может быть меньше %{count} символов)",
        "many": "недостаточной длины (не может быть меньше %{count} символов)",
        "one": "недостаточной длины (не может быть меньше %{count} символа)",
        "other": "недостаточной длины (не может быть меньше %{count} символа)"
      },
      "wrong_length": {
        "few": "неверной длины (может быть длиной ровно %{count} символа)",
        "many": "неверной длины (может быть длиной ровно %{count} символов)",
        "one": "неверной длины (может быть длиной ровно %{count} символ)",
        "other": "неверной длины (может быть длиной ровно %{count} символа)"
      }
    },
    "template": {
      "body": "Проблемы возникли со следующими полями:",
      "header": {
        "few": "%{model}: сохранение не удалось из-за %{count} ошибок",
        "many": "%{model}: сохранение не удалось из-за %{count} ошибок",
        "one": "%{model}: сохранение не удалось из-за %{count} ошибки",
        "other": "%{model}: сохранение не удалось из-за %{count} ошибки"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Выберите: "
    },
    "submit": {
      "create": "Создать %{model}",
      "submit": "Сохранить %{model}",
      "update": "Сохранить %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "few",
        "many",
        "other"
      ],
      "rule": "#<Proc:0x00007ff32a3c19f8 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/common_pluralizations/east_slavic.rb:15 (lambda)>"
    },
    "transliterate": {
      "rule": "#<Proc:0x00007ff32a36c8b8 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/transliteration.rb:113 (lambda)>"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "руб."
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 3,
      "round_mode": "default",
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": {
            "few": "миллиардов",
            "many": "миллиардов",
            "one": "миллиард",
            "other": "миллиардов"
          },
          "million": {
            "few": "миллионов",
            "many": "миллионов",
            "one": "миллион",
            "other": "миллионов"
          },
          "quadrillion": {
            "few": "квадриллионов",
            "many": "квадриллионов",
            "one": "квадриллион",
            "other": "квадриллионов"
          },
          "thousand": {
            "few": "тысяч",
            "many": "тысяч",
            "one": "тысяча",
            "other": "тысяч"
          },
          "trillion": {
            "few": "триллионов",
            "many": "триллионов",
            "one": "триллион",
            "other": "триллионов"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "few": "байта",
            "many": "байт",
            "one": "байт",
            "other": "байта"
          },
          "eb": "ЭБ",
          "gb": "ГБ",
          "kb": "КБ",
          "mb": "МБ",
          "pb": "ПБ",
          "tb": "ТБ"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "support": {
    "array": {
      "last_word_connector": " и ",
      "two_words_connector": " и ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "утра",
    "formats": {
      "default": "%a, %d %b %Y, %H:%M:%S %z",
      "long": "%d %B %Y, %H:%M",
      "short": "%d %b, %H:%M"
    },
    "pm": "вечера"
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
});
I18n.translations["lv"] = I18n.extend((I18n.translations["lv"] || {}), {
});
I18n.translations["lt"] = I18n.extend((I18n.translations["lt"] || {}), {
});
