import I18n from 'i18n-js'

window.I18n = I18n

require('../bundles/i18n/lv.js');
require('../bundles/i18n/lt.js');
require('../bundles/i18n/en.js');
require('../bundles/i18n/ru.js');


$(document).ready(() => {
  I18n.locale = $('body').data('locale');
  I18n.defaultLocale = $('body').data('default-locale');
});

export function t(key, i18nNamespace) {
  return I18n.t(`${i18nNamespace}.${key}`);
}

window.t = t
