import consumer from "./consumer"
import 'bootstrap/js/dist/modal'

consumer.subscriptions.create("ReportJobNotificationsChannel", {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const type = data.type 
    if (type === "success") {
      window.location = data.data_link
      return
    } else {
      showAlert(data.message)
    }
  }
});
