import { Controller } from "stimulus"
import {showLoadingScreen, hideLoadingScreen} from "../packs/loading-screen"

export default class extends Controller {
  connect() {
  }

  trigerLoading(event) {
    showLoadingScreen("Atskaite tiek sagatavota...")
  }

  checkField(event) {
    if( !$('#movement_report_date_range').val()) {
      showAlert('Nav norādīts vēlamais sprāgstvielu kustības atskaites laika periods!')
      event.preventDefault()
      return false
    }
  }

  error(event) {
    showAlert('Kļūda atskaites sagatvošanā!')
    hideLoadingScreen()
  }

  serveFile(event) {
    // window.location=event.detail[2].responseURL
    hideLoadingScreen()
    showAlert('Atskaite tiek sagatavota un drīz būs pieejama lejupielādei.', 'Izcili!')
  }
}
