I18n.translations || (I18n.translations = {});
I18n.translations["lt"] = I18n.extend((I18n.translations["lt"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Nepraeitos patikros: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Negalima ištrinti įrašų nes priklausomi %{record} egzistuoja",
          "has_one": "Negalima ištrinti įrašo nes priklausomas %{record} egzistuoja"
        }
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sek",
      "Pir",
      "Ant",
      "Tre",
      "Ket",
      "Pen",
      "Šeš"
    ],
    "abbr_month_names": [
      null,
      "Sau",
      "Vas",
      "Kov",
      "Bal",
      "Geg",
      "Bir",
      "Lie",
      "Rgp",
      "Rgs",
      "Spa",
      "Lap",
      "Grd"
    ],
    "day_names": [
      "sekmadienis",
      "pirmadienis",
      "antradienis",
      "trečiadienis",
      "ketvirtadienis",
      "penktadienis",
      "šeštadienis"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%Y m. %B %d d.",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "sausio",
      "vasario",
      "kovo",
      "balandžio",
      "gegužės",
      "birželio",
      "liepos",
      "rugpjūčio",
      "rugsėjo",
      "spalio",
      "lapkričio",
      "gruodžio"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "few": "apie %{count} valandas",
        "one": "apie %{count} valandą",
        "other": "apie %{count} valandų"
      },
      "about_x_months": {
        "few": "apie %{count} mėnesius",
        "one": "apie %{count} mėnesį",
        "other": "apie %{count} mėnesių"
      },
      "about_x_years": {
        "few": "apie %{count} metus",
        "one": "apie %{count} metus",
        "other": "apie %{count} metų"
      },
      "almost_x_years": {
        "few": "beveik %{count} metai",
        "one": "beveik %{count} metai",
        "other": "beveik %{count} metų"
      },
      "half_a_minute": "pusė minutės",
      "less_than_x_minutes": {
        "few": "mažiau nei %{count} minutės",
        "one": "mažiau nei %{count} minutė",
        "other": "mažiau nei %{count} minučių"
      },
      "less_than_x_seconds": {
        "few": "mažiau nei %{count} sekundės",
        "one": "mažiau nei %{count} sekundė",
        "other": "mažiau nei %{count} sekundžių"
      },
      "over_x_years": {
        "few": "virš %{count} metų",
        "one": "virš %{count} metų",
        "other": "virš %{count} metų"
      },
      "x_days": {
        "few": "%{count} dienos",
        "one": "%{count} diena",
        "other": "%{count} dienų"
      },
      "x_minutes": {
        "few": "%{count} minutės",
        "one": "%{count} minutė",
        "other": "%{count} minučių"
      },
      "x_months": {
        "few": "%{count} mėnesiai",
        "one": "%{count} mėnesis",
        "other": "%{count} mėnesių"
      },
      "x_seconds": {
        "few": "%{count} sekundės",
        "one": "%{count} sekundė",
        "other": "%{count} sekundžių"
      },
      "x_years": {
        "few": "%{count} metai",
        "one": "%{count} metai",
        "other": "%{count} metai"
      }
    },
    "prompts": {
      "day": "Diena",
      "hour": "Valanda",
      "minute": "Minutė",
      "month": "Mėnuo",
      "second": "Sekundės",
      "year": "Metai"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "turi būti patvirtintas",
      "blank": "negali būti tuščias",
      "confirmation": "neteisingai pakartotas",
      "empty": "negali būti tuščias",
      "equal_to": "turi būti lygus %{count}",
      "even": "turi būti lyginis skaičius",
      "exclusion": "yra rezervuotas",
      "greater_than": "turi būti didesnis už %{count}",
      "greater_than_or_equal_to": "turi būti didesnis arba lygus %{count}",
      "in": "turi būti skaičiuje %{count}",
      "inclusion": "nenumatyta reikšmė",
      "invalid": "neteisingas",
      "less_than": "turi būti mažesnis už %{count}",
      "less_than_or_equal_to": "turi būti mažesnis arba lygus %{count}",
      "model_invalid": "Tikrinimo klaida: %{errors}",
      "not_a_number": "ne skaičius",
      "not_an_integer": "privalo būti sveikas skaičius",
      "odd": "turi būti nelyginis skaičius",
      "other_than": "privalo būti kitoks nei %{count}",
      "present": "turi būti tuščias",
      "required": "turi egzistuoti",
      "taken": "jau užimtas",
      "too_long": {
        "few": "per ilgas (daugiausiai %{count} simboliai)",
        "one": "per ilgas (daugiausiai %{count} simbolis)",
        "other": "per ilgas (daugiausiai %{count} simbolių)"
      },
      "too_short": {
        "few": "per trumpas (mažiausiai %{count} simboliai)",
        "one": "per trumpas (mažiausiai %{count} simbolis)",
        "other": "per trumpas (mažiausiai %{count} simbolių)"
      },
      "wrong_length": "neteisingo ilgio (turi būti %{count} simboliai)"
    },
    "template": {
      "body": "Šiuose laukuose yra klaidų:",
      "header": {
        "few": "Išsaugant objektą %{model} rastos %{count} klaidos",
        "one": "Išsaugant objektą %{model} rasta %{count} klaida",
        "other": "Išsaugant objektą %{model} rasta %{count} klaidų"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Prašom pasirinkti"
    },
    "submit": {
      "create": "Sukurti %{model}",
      "submit": "Išsaugoti %{model}",
      "update": "Atnaujinti %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "few",
        "other"
      ],
      "rule": "#<Proc:0x00007ff32a3bea00 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/pluralization.rb:66 (lambda)>"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 3,
      "round_mode": "default",
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Milijardas",
          "million": "Milijonas",
          "quadrillion": "Kvadrilijonas",
          "thousand": "Tūkstantis",
          "trillion": "Trilijonas",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "few": "Baitai",
            "one": "Baitas",
            "other": "Baitų"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "%{number}.",
      "ordinals": "."
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "support": {
    "array": {
      "last_word_connector": " ir ",
      "two_words_connector": " ir ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%Y %B %d %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
});
I18n.translations["lv"] = I18n.extend((I18n.translations["lv"] || {}), {
});
I18n.translations["ru"] = I18n.extend((I18n.translations["ru"] || {}), {
});
