I18n.translations || (I18n.translations = {});
I18n.translations["lv"] = I18n.extend((I18n.translations["lv"] || {}), {
  "actions": "Darbības",
  "activerecord": {
    "attributes": {
      "destination": {
        "adress": "Adrese",
        "name": "Nosaukums"
      },
      "employee": {
        "active": "Aktīvs",
        "admin": "Administrators",
        "email": "E-pasts",
        "first_name": "Vārds",
        "last_name": "Uzvārds"
      },
      "explosive": {
        "available": "Pieejama",
        "box_number": "Kastes numurs",
        "code": "Vienības kods",
        "delivery_date": "Piegādes datums",
        "import_name": "Importa faila nosaukums",
        "item_number": "Vienības numurs",
        "manufacturer": "Ražotājs",
        "middlebox_number": "Iepakojuma numurs",
        "product_name": "Produkta nosaukums",
        "product_number": "Produkta numurs",
        "reason": "Iemesls",
        "takeout": "Izņemšana"
      },
      "item_setting": {
        "include_in_waybills": "Iekļaut pavadzīmēs",
        "item_count": "Vienību skaits",
        "item_name_display": "Nosaukums displejā",
        "item_name_import": "Vienības nosaukums",
        "manually_created": "Manuāli izveidots",
        "set_weight_manually": "Manuāls svars",
        "waybill_position": "Pozīcija pavadzīmē",
        "weight": "Svars"
      },
      "log": {
        "action": "Darbība",
        "author": "Veicējs",
        "corrected_explosives": "Labotās sprāgstvielas",
        "date": "Datums",
        "destination": "Darba objekts",
        "employee": "Darbinieks",
        "id_number": "ID numurs",
        "import_name": "Importa fails",
        "notes": "Piezīmes",
        "takeout": "Izņemšana",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "takeout_waybill_custom_items": "Izņemšanas pavadzīmes manuālā rinda",
        "vehicle": "Transportlīdzeklis"
      },
      "pdf_code_printout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "report": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout_waybill": {
        "Receiver": "Saņēmējs",
        "date": "Datums",
        "destination": "Objekts",
        "direction": "Virziens",
        "employee": "Darbinieks",
        "initally_edited": "Rediģēta",
        "issuer": "Nosūtītājs",
        "number": "Numurs",
        "sender": "Piegādātājs",
        "takeout": "Izņemšana",
        "to_object": "Uz objektu"
      },
      "takeout_waybill_custom_item": {
        "amount": "Daudzums",
        "item_setting": "Vienības iestatījumi",
        "name": "Nosaukums",
        "package_name": "Iepakojums",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "weight": "Svars"
      },
      "vehicle": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      }
    },
    "created_at": "Izveidots",
    "errors": {
      "messages": {
        "record_invalid": "Pārbaude neizdevās: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Nevar dzēst ierakstu, jo ir atkarīgi %{record}",
          "has_one": "Nevar dzēst ierakstu, jo ir atkarīgs %{record}"
        }
      }
    },
    "models": {
      "destination": "Darba Objekts",
      "employee": "Darbinieks",
      "explosive": "Sprāgstviela",
      "item_setting": "Vienības iestatījums",
      "log": "Darbību žurnāls",
      "pdf_code_printout": "PDF kodu izdruka",
      "report": "Atskaite",
      "takeout": "Sprāgstvielu izņemšana",
      "takeout_waybill": "Pavadzīme",
      "takeout_waybill_custom_item": "Manuāli ievadāmā vienība",
      "vehicle": "Transports"
    },
    "removed": "Dzēsts",
    "updated_at": "Labots"
  },
  "back": "Atpakaļ",
  "cancel": "Atcelt",
  "date": {
    "abbr_day_names": [
      "Sv.",
      "P.",
      "O.",
      "T.",
      "C.",
      "Pk.",
      "S."
    ],
    "abbr_month_names": [
      null,
      "Janv",
      "Febr",
      "Marts",
      "Apr",
      "Maijs",
      "Jūn",
      "Jūl",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "svētdiena",
      "pirmdiena",
      "otrdiena",
      "trešdiena",
      "ceturtdiena",
      "piektdiena",
      "sestdiena"
    ],
    "formats": {
      "default": "%d.%m.%Y.",
      "long": "%Y. gada %e. %B",
      "short": "%e. %B"
    },
    "month_names": [
      null,
      "janvārī",
      "februārī",
      "martā",
      "aprīlī",
      "maijā",
      "jūnijā",
      "jūlijā",
      "augustā",
      "septembrī",
      "oktobrī",
      "novembrī",
      "decembrī"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "apmēram %{count} stunda",
        "other": "apmēram %{count} stundas",
        "zero": "apmēram %{count} stundas"
      },
      "about_x_months": {
        "one": "apmēram %{count} mēnesis",
        "other": "apmēram %{count} mēneši",
        "zero": "apmēram %{count} mēneši"
      },
      "about_x_years": {
        "one": "apmēram %{count} gads",
        "other": "apmēram %{count} gadi",
        "zero": "apmēram %{count} gadi"
      },
      "almost_x_years": {
        "one": "gandrīz %{count} gads",
        "other": "gandrīz %{count} gadi",
        "zero": "gandrīz %{count} gadi"
      },
      "half_a_minute": "pusminūte",
      "less_than_x_minutes": {
        "one": "mazāk par %{count} minūti",
        "other": "mazāk par %{count} minūtēm",
        "zero": "mazāk par %{count} minūtēm"
      },
      "less_than_x_seconds": {
        "one": "mazāk par %{count} sekundi",
        "other": "mazāk par %{count} sekundēm",
        "zero": "mazāk par %{count} sekundēm"
      },
      "over_x_years": {
        "one": "vairāk kā %{count} gads",
        "other": "vairāk kā %{count} gadi",
        "zero": "vairāk kā %{count} gadi"
      },
      "x_days": {
        "one": "%{count} diena",
        "other": "%{count} dienas",
        "zero": "%{count} dienas"
      },
      "x_minutes": {
        "one": "%{count} minūte",
        "other": "%{count} minūtes",
        "zero": "%{count} minūtes"
      },
      "x_months": {
        "one": "%{count} mēnesis",
        "other": "%{count} mēneši",
        "zero": "%{count} mēneši"
      },
      "x_seconds": {
        "one": "%{count} sekunde",
        "other": "%{count} sekundes",
        "zero": "%{count} sekundes"
      }
    },
    "prompts": {
      "day": "diena",
      "hour": "stunda",
      "minute": "minūte",
      "month": "mēnesis",
      "second": "sekunde",
      "year": "gads"
    }
  },
  "delete": "Dzēst",
  "destinations": {
    "edit": {
      "title": "Objekta rediģēšana"
    },
    "form": {
      "adress_note": "Adresei jābūt unikālai.",
      "adress_placeholder": "Objekta adrese",
      "name_placeholder": "Objekta nosaukums"
    },
    "new": {
      "title": "Jauna objekta pievienošana"
    }
  },
  "edit": "Rediģēt",
  "edit_object": "Rediģēt",
  "employees": {
    "edit": {
      "title": "Darbinieka rediģēšana"
    },
    "form": {
      "email_note": "Tam jābūt @gmail.com vai @balrock.lv epastam.",
      "email_placeholder": "Ievadi darbinieka epastu",
      "first_name_note": "Vārds nderīkst saturēt ciparus.",
      "first_name_placeholder": "Ievadi darbinieka vārdu",
      "last_name_note": "Uzvārds nedrīkst saturēt ciparus.",
      "last_name_placeholder": "Ievadi darbinieka uzvārdu"
    },
    "index": {
      "subtitle": "Darbineku un piekļuves pāŗvaldība",
      "title": "Darbinieki"
    },
    "new": {
      "title": "Jauna darbinieka pievienošana"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "ir jāpiekrīt",
      "blank": "ir jābūt aizpildītam",
      "confirmation": "nesakrīt ar apstiprinājumu",
      "empty": "ir jābūt aizpildītam",
      "equal_to": "ir jābūt vienādam ar %{count}",
      "even": "ir jābūt pāra skaitlim",
      "exclusion": "nav pieejams",
      "greater_than": "ir jābūt lielākam par %{count}",
      "greater_than_or_equal_to": "ir jābūt lielākam vai vienādam ar %{count}",
      "inclusion": "nav iekļauts sarakstā",
      "invalid": "nav derīgs",
      "less_than": "ir jābūt mazākam par %{count}",
      "less_than_or_equal_to": "ir jābūt mazākam vai vienādam ar %{count}",
      "model_invalid": "Validācija neizdevās: %{errors}",
      "not_a_number": "nav skaitlis",
      "not_an_integer": "ir jābūt veselam skaitlim",
      "odd": "ir jābūt nepāra skaitlim",
      "other_than": "jābūt citam nekā %{count}",
      "present": "jābūt tukšam",
      "required": "ir jābūt",
      "taken": "ir jau aizņemts",
      "too_long": {
        "one": "ir par garu (maksimums ir %{count} simbols)",
        "other": "ir par garu (maksimums ir %{count} simboli)",
        "zero": "ir par garu (maksimums ir %{count} simboli)"
      },
      "too_short": {
        "one": "ir par īsu (minimums ir %{count} simbols)",
        "other": "ir par īsu (minimums ir %{count} simboli)",
        "zero": "ir par īsu (minimums ir %{count} simboli)"
      },
      "wrong_length": {
        "one": "ir nepareizs garums (jābūt %{count} simbolam)",
        "other": "ir nepareizs garums (jābūt %{count} simboliem)",
        "zero": "ir nepareizs garums (jābūt %{count} simboliem)"
      }
    },
    "template": {
      "body": "Problēmas ir šajos ievades laukos:",
      "header": {
        "one": "Dēļ %{count} kļūdas šis %{model} netika saglabāts",
        "other": "Dēļ %{count} kļūdām šis %{model} netika saglabāts",
        "zero": "Dēļ %{count} kļūdām šis %{model} netika saglabāts"
      }
    }
  },
  "explosives": {
    "amount_correction": {
      "no_camera_alert": "Kamera nav pieejama!",
      "no_camera_alert_message": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "subtitle": "Noskenē kodu vai ievadi numuru un izvēlies turpmāko darbību",
      "title": "Sprāgstvielu apjoma korekcija"
    },
    "correction_code_scanner": {
      "camera_alert": "Kamera nav pieejama!",
      "correction_modal_title": "Apjoma korekcija",
      "explosive_type": "Sprāgstviela",
      "explosive_units": "vienības",
      "no_camera_permission": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "remove_explosives": "Dzēst no noliktavas",
      "revive_explosives": "Atgriezt noliktavā",
      "some_units_deleted": "bet dažas no tajā esošajām vienībām jau ir dzēstas."
    },
    "file_upload": {
      "files_selected": " faili izvēlēti",
      "import_notification": "Dati tiek importēti, lūdzu uzgaidiet."
    },
    "index": {
      "title": "Kļūdainie ieraksti"
    },
    "new": {
      "import": "Importēt",
      "upload_note": "Izvēlies 1 vai vairākus .xml vai .xlsx failu."
    }
  },
  "helpers": {
    "select": {
      "prompt": "Lūdzu izvēlies"
    },
    "submit": {
      "create": "Izveidot %{model}",
      "submit": "Saglabāt %{model}",
      "update": "Atjaunināt %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007ff32a3b8420 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/pluralization.rb:85 (lambda)>"
    }
  },
  "item_settings": {
    "index": {
      "subtitle": "Vienību svara un pavadzīmju iestatījumi",
      "title": "Vienību iestatījumi"
    },
    "item_setting_row": {
      "weight_note": "Noklusējuma svars"
    }
  },
  "logs": {
    "corrected_explosives": {
      "corrected_amount": "Laboto vienību skaits:",
      "corrected_explosives_table": "Koriģētās sprāgstvielas",
      "correction_action": "Korekcijas darbība:",
      "correction_date": "Datums:",
      "deletion": "❌ Dzēšana",
      "employee": "Korekciju veicējs:",
      "return": "✅ Atgriešana noliktavā",
      "title": "Sprāgstvielu korekcijas pārskats"
    },
    "helper_row": {
      "changes": "Izmaiņas",
      "corrected_explosives_count": "Koriģēto sprāgstvielu skaits",
      "correction_action": "Korekcijas darbība",
      "deletion": "Dzēšana",
      "edited": "Rediģēts",
      "edited_object": "Rediģētais",
      "error": "Kaut kas nogāja greizi",
      "imported_explosives_count": "Pievienoto sprāgstvielu skaits",
      "precise_time": "Precīzs laiks",
      "return": "Atgriešana noliktavā",
      "returned_explosives_count": "Atgriezto sprāgstvielu skaits",
      "taken_explosives_count": "Izņemto sprāgstvielu skaits",
      "takeout_responsible_employee": "Izņemšanas atbildīgais (izveidotājs)",
      "to_overview": "Uz pārskatu"
    },
    "index": {
      "filtering": "Filtrēšana",
      "subtitle": "Notikumi ir sakārtoti dilstošā secībā pēc to vecuma",
      "title": "Darbību žurnāls"
    },
    "log_querry": {
      "clear_filters": "Dzēst filtrus",
      "filter": "Filtrēt"
    },
    "log_table": {
      "no_results": "Pēc dotajiem kritērijiem nekas netika atrasts"
    }
  },
  "main": {
    "index": {
      "active_takeouts": "Aktīvās sprāgstvielu izņemšanas:",
      "current_employee": "Lietotājs:",
      "employee_admin": "Administrators",
      "employee_regular": "Darbinieks",
      "employee_role": "Statuss:",
      "explosives_in_warehouse": "Sprāgstvielu skaits noliktavā:",
      "no_actions": "Tev vēl nav veiktu darbību.",
      "previous_actions": "Tavas pēdējās darbības: ",
      "title": "Balrock sprāgstvielu noliktavas uzskaite"
    }
  },
  "new_object": "Pievienot",
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": {
            "one": "miljards",
            "other": "miljardi",
            "zero": "miljardi"
          },
          "million": {
            "one": "miljons",
            "other": "miljoni",
            "zero": "miljoni"
          },
          "quadrillion": {
            "one": "kvadriljons",
            "other": "kvadriljoni",
            "zero": "kvadriljoni"
          },
          "thousand": {
            "one": "tūkstotis",
            "other": "tūkstoši",
            "zero": "tūkstoši"
          },
          "trillion": {
            "one": "triljons",
            "other": "triljoni",
            "zero": "triljoni"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "baits",
            "other": "baiti",
            "zero": "baiti"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "%{number}.",
      "ordinals": "."
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pagy": {
    "combo_nav_js": "Lapa %{page_input} no %{pages}",
    "info": {
      "multiple_pages": "Tiek parādītas %{item_name} <b>%{from}-%{to}</b> of <b>%{count}</b> in total",
      "no_items": "%{item_name} netika atrasts/a",
      "single_page": "Tiek parādīta <b>%{count}</b> %{item_name}"
    },
    "item_name": {
      "one": "Ieraksts",
      "other": "Ieraksti"
    },
    "items_selector_js": "Lapā iekļaut %{items_input} %{item_name} ierakstus",
    "nav": {
      "gap": "&hellip;",
      "next": "Nākamā&nbsp;&rsaquo;",
      "prev": "&lsaquo;&nbsp;Iepriekšējā"
    }
  },
  "pdf_code_printouts": {
    "index": {
      "no_results": "Nekas netika atrasts",
      "package_search": "Drukājamo vienību kastu meklēšana pēc nosaukuma",
      "package_search_placeholder": "Nosaukums vai tā daļa",
      "search": "Meklēt",
      "title": "Vienību kodu duplikātu printēšana"
    },
    "table_row": {
      "get_pdf": "Iegūt PDF kodu lapu"
    }
  },
  "reports": {
    "available_explosives": {
      "headers": [
        "Kods",
        "Ražotājs",
        "Nosaukums",
        "Kastes numurs",
        "Iepakojuma numurs",
        "Vienības numurs",
        "Importa fails",
        "Atrašanās vieta",
        "Atbildīgais"
      ],
      "no_employee": "Vienībai nav atbildīgā",
      "report_row_title": "Pieejamo sprāgstvielu pārskats %{date}",
      "worksheet_title": "Pieejamās sprāgstvielas"
    },
    "explosive_movement": {
      "sheet_name": {
        "active": "AktīvaIzņemšana-%{index}-%{date}",
        "returned": "AtgrieztaIzņemšana-%{index}-%{date}"
      },
      "states": {
        "active": "izņemta",
        "returned": "atgriezta",
        "used": "izlietota"
      },
      "takeout_explosives_headers": [
        "Kods",
        "Nosaukums",
        "Kaste",
        "Iepakojums",
        "Sprāgstviela",
        "Importa fails",
        "Statuss"
      ],
      "takeout_info_headers": [
        "Darbinieks",
        "Darba objekts",
        "Transportlīdzeklis",
        "Statuss",
        "Izņemto vienību skaits",
        "Izlietoto vienību skaits"
      ],
      "worksheet_title": "Sprāgstvielu izņemšanas/atgriešanas pārskats"
    },
    "index": {
      "available_explosives_count": "Pašlaik pieejamas %{count} vienības",
      "available_explosives_report": "Pieejamās sprāgstvielas",
      "date_range": "Laika periods",
      "explosive_movement_report": "Sprāgstvielu kustība laika periodā",
      "explosive_summary": "Sprāgstvielu atlikumi noliktavā pēc nosaukuma",
      "explosives_in_warehouse": "Noliktavā atrodas šādas vienības:",
      "prepare_report": "Sagatavot",
      "title": "Pieejamās sistēmas atskaites .xlsx formātā",
      "total_amount": "Kopā:"
    }
  },
  "save": "Saglabāt",
  "sessions": {
    "new": {
      "google_auth": "Pieslēgties ar Google",
      "title": "Pieslēgties noliktavas uzskaitei"
    }
  },
  "state": "Ieslēgts",
  "support": {
    "array": {
      "last_word_connector": " un ",
      "two_words_connector": " un ",
      "words_connector": ", "
    }
  },
  "takeout_waybills": {
    "edit": {
      "manual_items": "Manuāli ievadāmās vienības",
      "title": "Pavadzīmes %{number} rediģēšana",
      "view_waybill": "Skatīt pavadzīmi",
      "waybill_persons": "Pavadzīmes personas"
    },
    "index": {
      "subtitle": "Visu izveidoto pavadzīmju saraksts",
      "title": "Pavadzīmes"
    }
  },
  "takeouts": {
    "index": null
  },
  "time": {
    "am": "priekšpusdiena",
    "formats": {
      "default": "%Y. gada %e. %B, %H:%M",
      "long": "%Y. gada %e. %B, %H:%M:%S",
      "short": "%d.%m.%Y., %H:%M"
    },
    "pm": "pēcpusdiena"
  },
  "view": "Skatīt"
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "actions": "Darbības",
  "activerecord": {
    "attributes": {
      "destination": {
        "adress": "Adrese",
        "name": "Nosaukums"
      },
      "employee": {
        "active": "Aktīvs",
        "admin": "Administrators",
        "email": "E-pasts",
        "first_name": "Vārds",
        "last_name": "Uzvārds"
      },
      "explosive": {
        "available": "Pieejama",
        "box_number": "Kastes numurs",
        "code": "Vienības kods",
        "delivery_date": "Piegādes datums",
        "import_name": "Importa faila nosaukums",
        "item_number": "Vienības numurs",
        "manufacturer": "Ražotājs",
        "middlebox_number": "Iepakojuma numurs",
        "product_name": "Produkta nosaukums",
        "product_number": "Produkta numurs",
        "reason": "Iemesls",
        "takeout": "Izņemšana"
      },
      "item_setting": {
        "include_in_waybills": "Iekļaut pavadzīmēs",
        "item_count": "Vienību skaits",
        "item_name_display": "Nosaukums displejā",
        "item_name_import": "Vienības nosaukums",
        "manually_created": "Manuāli izveidots",
        "set_weight_manually": "Manuāls svars",
        "waybill_position": "Pozīcija pavadzīmē",
        "weight": "Svars"
      },
      "log": {
        "action": "Darbība",
        "author": "Veicējs",
        "corrected_explosives": "Labotās sprāgstvielas",
        "date": "Datums",
        "destination": "Darba objekts",
        "employee": "Darbinieks",
        "id_number": "ID numurs",
        "import_name": "Importa fails",
        "notes": "Piezīmes",
        "takeout": "Izņemšana",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "takeout_waybill_custom_items": "Izņemšanas pavadzīmes manuālā rinda",
        "vehicle": "Transportlīdzeklis"
      },
      "pdf_code_printout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "report": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout_waybill": {
        "Receiver": "Saņēmējs",
        "date": "Datums",
        "destination": "Objekts",
        "direction": "Virziens",
        "employee": "Darbinieks",
        "initally_edited": "Rediģēta",
        "issuer": "Nosūtītājs",
        "number": "Numurs",
        "sender": "Piegādātājs",
        "takeout": "Izņemšana",
        "to_object": "Uz objektu"
      },
      "takeout_waybill_custom_item": {
        "amount": "Daudzums",
        "item_setting": "Vienības iestatījumi",
        "name": "Nosaukums",
        "package_name": "Iepakojums",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "weight": "Svars"
      },
      "vehicle": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      }
    },
    "created_at": "Izveidots",
    "errors": {
      "messages": {
        "record_invalid": "Pārbaude neizdevās: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Nevar dzēst ierakstu, jo ir atkarīgi %{record}",
          "has_one": "Nevar dzēst ierakstu, jo ir atkarīgs %{record}"
        }
      }
    },
    "models": {
      "destination": "Darba Objekts",
      "employee": "Darbinieks",
      "explosive": "Sprāgstviela",
      "item_setting": "Vienības iestatījums",
      "log": "Darbību žurnāls",
      "pdf_code_printout": "PDF kodu izdruka",
      "report": "Atskaite",
      "takeout": "Sprāgstvielu izņemšana",
      "takeout_waybill": "Pavadzīme",
      "takeout_waybill_custom_item": "Manuāli ievadāmā vienība",
      "vehicle": "Transports"
    },
    "removed": "Dzēsts",
    "updated_at": "Labots"
  },
  "back": "Atpakaļ",
  "cancel": "Atcelt",
  "date": {
    "abbr_day_names": [
      "Sv.",
      "P.",
      "O.",
      "T.",
      "C.",
      "Pk.",
      "S."
    ],
    "abbr_month_names": [
      null,
      "Janv",
      "Febr",
      "Marts",
      "Apr",
      "Maijs",
      "Jūn",
      "Jūl",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "svētdiena",
      "pirmdiena",
      "otrdiena",
      "trešdiena",
      "ceturtdiena",
      "piektdiena",
      "sestdiena"
    ],
    "formats": {
      "default": "%d.%m.%Y.",
      "long": "%Y. gada %e. %B",
      "short": "%e. %B"
    },
    "month_names": [
      null,
      "janvārī",
      "februārī",
      "martā",
      "aprīlī",
      "maijā",
      "jūnijā",
      "jūlijā",
      "augustā",
      "septembrī",
      "oktobrī",
      "novembrī",
      "decembrī"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "apmēram %{count} stunda",
        "other": "apmēram %{count} stundas",
        "zero": "apmēram %{count} stundas"
      },
      "about_x_months": {
        "one": "apmēram %{count} mēnesis",
        "other": "apmēram %{count} mēneši",
        "zero": "apmēram %{count} mēneši"
      },
      "about_x_years": {
        "one": "apmēram %{count} gads",
        "other": "apmēram %{count} gadi",
        "zero": "apmēram %{count} gadi"
      },
      "almost_x_years": {
        "one": "gandrīz %{count} gads",
        "other": "gandrīz %{count} gadi",
        "zero": "gandrīz %{count} gadi"
      },
      "half_a_minute": "pusminūte",
      "less_than_x_minutes": {
        "one": "mazāk par %{count} minūti",
        "other": "mazāk par %{count} minūtēm",
        "zero": "mazāk par %{count} minūtēm"
      },
      "less_than_x_seconds": {
        "one": "mazāk par %{count} sekundi",
        "other": "mazāk par %{count} sekundēm",
        "zero": "mazāk par %{count} sekundēm"
      },
      "over_x_years": {
        "one": "vairāk kā %{count} gads",
        "other": "vairāk kā %{count} gadi",
        "zero": "vairāk kā %{count} gadi"
      },
      "x_days": {
        "one": "%{count} diena",
        "other": "%{count} dienas",
        "zero": "%{count} dienas"
      },
      "x_minutes": {
        "one": "%{count} minūte",
        "other": "%{count} minūtes",
        "zero": "%{count} minūtes"
      },
      "x_months": {
        "one": "%{count} mēnesis",
        "other": "%{count} mēneši",
        "zero": "%{count} mēneši"
      },
      "x_seconds": {
        "one": "%{count} sekunde",
        "other": "%{count} sekundes",
        "zero": "%{count} sekundes"
      }
    },
    "prompts": {
      "day": "diena",
      "hour": "stunda",
      "minute": "minūte",
      "month": "mēnesis",
      "second": "sekunde",
      "year": "gads"
    }
  },
  "delete": "Dzēst",
  "destinations": {
    "edit": {
      "title": "Objekta rediģēšana"
    },
    "form": {
      "adress_note": "Adresei jābūt unikālai.",
      "adress_placeholder": "Objekta adrese",
      "name_placeholder": "Objekta nosaukums"
    },
    "new": {
      "title": "Jauna objekta pievienošana"
    }
  },
  "edit": "Rediģēt",
  "edit_object": "Rediģēt",
  "employees": {
    "edit": {
      "title": "Darbinieka rediģēšana"
    },
    "form": {
      "email_note": "Tam jābūt @gmail.com vai @balrock.lv epastam.",
      "email_placeholder": "Ievadi darbinieka epastu",
      "first_name_note": "Vārds nderīkst saturēt ciparus.",
      "first_name_placeholder": "Ievadi darbinieka vārdu",
      "last_name_note": "Uzvārds nedrīkst saturēt ciparus.",
      "last_name_placeholder": "Ievadi darbinieka uzvārdu"
    },
    "index": {
      "subtitle": "Darbineku un piekļuves pāŗvaldība",
      "title": "Darbinieki"
    },
    "new": {
      "title": "Jauna darbinieka pievienošana"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "ir jāpiekrīt",
      "blank": "ir jābūt aizpildītam",
      "confirmation": "nesakrīt ar apstiprinājumu",
      "empty": "ir jābūt aizpildītam",
      "equal_to": "ir jābūt vienādam ar %{count}",
      "even": "ir jābūt pāra skaitlim",
      "exclusion": "nav pieejams",
      "greater_than": "ir jābūt lielākam par %{count}",
      "greater_than_or_equal_to": "ir jābūt lielākam vai vienādam ar %{count}",
      "inclusion": "nav iekļauts sarakstā",
      "invalid": "nav derīgs",
      "less_than": "ir jābūt mazākam par %{count}",
      "less_than_or_equal_to": "ir jābūt mazākam vai vienādam ar %{count}",
      "model_invalid": "Validācija neizdevās: %{errors}",
      "not_a_number": "nav skaitlis",
      "not_an_integer": "ir jābūt veselam skaitlim",
      "odd": "ir jābūt nepāra skaitlim",
      "other_than": "jābūt citam nekā %{count}",
      "present": "jābūt tukšam",
      "required": "ir jābūt",
      "taken": "ir jau aizņemts",
      "too_long": {
        "one": "ir par garu (maksimums ir %{count} simbols)",
        "other": "ir par garu (maksimums ir %{count} simboli)",
        "zero": "ir par garu (maksimums ir %{count} simboli)"
      },
      "too_short": {
        "one": "ir par īsu (minimums ir %{count} simbols)",
        "other": "ir par īsu (minimums ir %{count} simboli)",
        "zero": "ir par īsu (minimums ir %{count} simboli)"
      },
      "wrong_length": {
        "one": "ir nepareizs garums (jābūt %{count} simbolam)",
        "other": "ir nepareizs garums (jābūt %{count} simboliem)",
        "zero": "ir nepareizs garums (jābūt %{count} simboliem)"
      }
    },
    "template": {
      "body": "Problēmas ir šajos ievades laukos:",
      "header": {
        "one": "Dēļ %{count} kļūdas šis %{model} netika saglabāts",
        "other": "Dēļ %{count} kļūdām šis %{model} netika saglabāts",
        "zero": "Dēļ %{count} kļūdām šis %{model} netika saglabāts"
      }
    }
  },
  "explosives": {
    "amount_correction": {
      "no_camera_alert": "Kamera nav pieejama!",
      "no_camera_alert_message": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "subtitle": "Noskenē kodu vai ievadi numuru un izvēlies turpmāko darbību",
      "title": "Sprāgstvielu apjoma korekcija"
    },
    "correction_code_scanner": {
      "camera_alert": "Kamera nav pieejama!",
      "correction_modal_title": "Apjoma korekcija",
      "explosive_type": "Sprāgstviela",
      "explosive_units": "vienības",
      "no_camera_permission": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "remove_explosives": "Dzēst no noliktavas",
      "revive_explosives": "Atgriezt noliktavā",
      "some_units_deleted": "bet dažas no tajā esošajām vienībām jau ir dzēstas."
    },
    "file_upload": {
      "files_selected": " faili izvēlēti",
      "import_notification": "Dati tiek importēti, lūdzu uzgaidiet."
    },
    "index": {
      "title": "Kļūdainie ieraksti"
    },
    "new": {
      "import": "Importēt",
      "upload_note": "Izvēlies 1 vai vairākus .xml vai .xlsx failu."
    }
  },
  "helpers": {
    "select": {
      "prompt": "Lūdzu izvēlies"
    },
    "submit": {
      "create": "Izveidot %{model}",
      "submit": "Saglabāt %{model}",
      "update": "Atjaunināt %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007ff32a3b8420 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/pluralization.rb:85 (lambda)>"
    }
  },
  "item_settings": {
    "index": {
      "subtitle": "Vienību svara un pavadzīmju iestatījumi",
      "title": "Vienību iestatījumi"
    },
    "item_setting_row": {
      "weight_note": "Noklusējuma svars"
    }
  },
  "logs": {
    "corrected_explosives": {
      "corrected_amount": "Laboto vienību skaits:",
      "corrected_explosives_table": "Koriģētās sprāgstvielas",
      "correction_action": "Korekcijas darbība:",
      "correction_date": "Datums:",
      "deletion": "❌ Dzēšana",
      "employee": "Korekciju veicējs:",
      "return": "✅ Atgriešana noliktavā",
      "title": "Sprāgstvielu korekcijas pārskats"
    },
    "helper_row": {
      "changes": "Izmaiņas",
      "corrected_explosives_count": "Koriģēto sprāgstvielu skaits",
      "correction_action": "Korekcijas darbība",
      "deletion": "Dzēšana",
      "edited": "Rediģēts",
      "edited_object": "Rediģētais",
      "error": "Kaut kas nogāja greizi",
      "imported_explosives_count": "Pievienoto sprāgstvielu skaits",
      "precise_time": "Precīzs laiks",
      "return": "Atgriešana noliktavā",
      "returned_explosives_count": "Atgriezto sprāgstvielu skaits",
      "taken_explosives_count": "Izņemto sprāgstvielu skaits",
      "takeout_responsible_employee": "Izņemšanas atbildīgais (izveidotājs)",
      "to_overview": "Uz pārskatu"
    },
    "index": {
      "filtering": "Filtrēšana",
      "subtitle": "Notikumi ir sakārtoti dilstošā secībā pēc to vecuma",
      "title": "Darbību žurnāls"
    },
    "log_querry": {
      "clear_filters": "Dzēst filtrus",
      "filter": "Filtrēt"
    },
    "log_table": {
      "no_results": "Pēc dotajiem kritērijiem nekas netika atrasts"
    }
  },
  "main": {
    "index": {
      "active_takeouts": "Aktīvās sprāgstvielu izņemšanas:",
      "current_employee": "Lietotājs:",
      "employee_admin": "Administrators",
      "employee_regular": "Darbinieks",
      "employee_role": "Statuss:",
      "explosives_in_warehouse": "Sprāgstvielu skaits noliktavā:",
      "no_actions": "Tev vēl nav veiktu darbību.",
      "previous_actions": "Tavas pēdējās darbības: ",
      "title": "Balrock sprāgstvielu noliktavas uzskaite"
    }
  },
  "new_object": "Pievienot",
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": {
            "one": "miljards",
            "other": "miljardi",
            "zero": "miljardi"
          },
          "million": {
            "one": "miljons",
            "other": "miljoni",
            "zero": "miljoni"
          },
          "quadrillion": {
            "one": "kvadriljons",
            "other": "kvadriljoni",
            "zero": "kvadriljoni"
          },
          "thousand": {
            "one": "tūkstotis",
            "other": "tūkstoši",
            "zero": "tūkstoši"
          },
          "trillion": {
            "one": "triljons",
            "other": "triljoni",
            "zero": "triljoni"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "baits",
            "other": "baiti",
            "zero": "baiti"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "%{number}.",
      "ordinals": "."
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pagy": {
    "combo_nav_js": "Lapa %{page_input} no %{pages}",
    "info": {
      "multiple_pages": "Tiek parādītas %{item_name} <b>%{from}-%{to}</b> of <b>%{count}</b> in total",
      "no_items": "%{item_name} netika atrasts/a",
      "single_page": "Tiek parādīta <b>%{count}</b> %{item_name}"
    },
    "item_name": {
      "one": "Ieraksts",
      "other": "Ieraksti"
    },
    "items_selector_js": "Lapā iekļaut %{items_input} %{item_name} ierakstus",
    "nav": {
      "gap": "&hellip;",
      "next": "Nākamā&nbsp;&rsaquo;",
      "prev": "&lsaquo;&nbsp;Iepriekšējā"
    }
  },
  "pdf_code_printouts": {
    "index": {
      "no_results": "Nekas netika atrasts",
      "package_search": "Drukājamo vienību kastu meklēšana pēc nosaukuma",
      "package_search_placeholder": "Nosaukums vai tā daļa",
      "search": "Meklēt",
      "title": "Vienību kodu duplikātu printēšana"
    },
    "table_row": {
      "get_pdf": "Iegūt PDF kodu lapu"
    }
  },
  "reports": {
    "available_explosives": {
      "headers": [
        "Kods",
        "Ražotājs",
        "Nosaukums",
        "Kastes numurs",
        "Iepakojuma numurs",
        "Vienības numurs",
        "Importa fails",
        "Atrašanās vieta",
        "Atbildīgais"
      ],
      "no_employee": "Vienībai nav atbildīgā",
      "report_row_title": "Pieejamo sprāgstvielu pārskats %{date}",
      "worksheet_title": "Pieejamās sprāgstvielas"
    },
    "explosive_movement": {
      "sheet_name": {
        "active": "AktīvaIzņemšana-%{index}-%{date}",
        "returned": "AtgrieztaIzņemšana-%{index}-%{date}"
      },
      "states": {
        "active": "izņemta",
        "returned": "atgriezta",
        "used": "izlietota"
      },
      "takeout_explosives_headers": [
        "Kods",
        "Nosaukums",
        "Kaste",
        "Iepakojums",
        "Sprāgstviela",
        "Importa fails",
        "Statuss"
      ],
      "takeout_info_headers": [
        "Darbinieks",
        "Darba objekts",
        "Transportlīdzeklis",
        "Statuss",
        "Izņemto vienību skaits",
        "Izlietoto vienību skaits"
      ],
      "worksheet_title": "Sprāgstvielu izņemšanas/atgriešanas pārskats"
    },
    "index": {
      "available_explosives_count": "Pašlaik pieejamas %{count} vienības",
      "available_explosives_report": "Pieejamās sprāgstvielas",
      "date_range": "Laika periods",
      "explosive_movement_report": "Sprāgstvielu kustība laika periodā",
      "explosive_summary": "Sprāgstvielu atlikumi noliktavā pēc nosaukuma",
      "explosives_in_warehouse": "Noliktavā atrodas šādas vienības:",
      "prepare_report": "Sagatavot",
      "title": "Pieejamās sistēmas atskaites .xlsx formātā",
      "total_amount": "Kopā:"
    }
  },
  "save": "Saglabāt",
  "sessions": {
    "new": {
      "google_auth": "Pieslēgties ar Google",
      "title": "Pieslēgties noliktavas uzskaitei"
    }
  },
  "state": "Ieslēgts",
  "support": {
    "array": {
      "last_word_connector": " un ",
      "two_words_connector": " un ",
      "words_connector": ", "
    }
  },
  "takeout_waybills": {
    "edit": {
      "manual_items": "Manuāli ievadāmās vienības",
      "title": "Pavadzīmes %{number} rediģēšana",
      "view_waybill": "Skatīt pavadzīmi",
      "waybill_persons": "Pavadzīmes personas"
    },
    "index": {
      "subtitle": "Visu izveidoto pavadzīmju saraksts",
      "title": "Pavadzīmes"
    }
  },
  "takeouts": {
    "index": null
  },
  "time": {
    "am": "priekšpusdiena",
    "formats": {
      "default": "%Y. gada %e. %B, %H:%M",
      "long": "%Y. gada %e. %B, %H:%M:%S",
      "short": "%d.%m.%Y., %H:%M"
    },
    "pm": "pēcpusdiena"
  },
  "view": "Skatīt"
});
I18n.translations["lt"] = I18n.extend((I18n.translations["lt"] || {}), {
  "actions": "Darbības",
  "activerecord": {
    "attributes": {
      "destination": {
        "adress": "Adrese",
        "name": "Nosaukums"
      },
      "employee": {
        "active": "Aktīvs",
        "admin": "Administrators",
        "email": "E-pasts",
        "first_name": "Vārds",
        "last_name": "Uzvārds"
      },
      "explosive": {
        "available": "Pieejama",
        "box_number": "Kastes numurs",
        "code": "Vienības kods",
        "delivery_date": "Piegādes datums",
        "import_name": "Importa faila nosaukums",
        "item_number": "Vienības numurs",
        "manufacturer": "Ražotājs",
        "middlebox_number": "Iepakojuma numurs",
        "product_name": "Produkta nosaukums",
        "product_number": "Produkta numurs",
        "reason": "Iemesls",
        "takeout": "Izņemšana"
      },
      "item_setting": {
        "include_in_waybills": "Iekļaut pavadzīmēs",
        "item_count": "Vienību skaits",
        "item_name_display": "Nosaukums displejā",
        "item_name_import": "Vienības nosaukums",
        "manually_created": "Manuāli izveidots",
        "set_weight_manually": "Manuāls svars",
        "waybill_position": "Pozīcija pavadzīmē",
        "weight": "Svars"
      },
      "log": {
        "action": "Darbība",
        "author": "Veicējs",
        "corrected_explosives": "Labotās sprāgstvielas",
        "date": "Datums",
        "destination": "Darba objekts",
        "employee": "Darbinieks",
        "id_number": "ID numurs",
        "import_name": "Importa fails",
        "notes": "Piezīmes",
        "takeout": "Izņemšana",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "takeout_waybill_custom_items": "Izņemšanas pavadzīmes manuālā rinda",
        "vehicle": "Transportlīdzeklis"
      },
      "pdf_code_printout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "report": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout_waybill": {
        "Receiver": "Saņēmējs",
        "date": "Datums",
        "destination": "Objekts",
        "direction": "Virziens",
        "employee": "Darbinieks",
        "initally_edited": "Rediģēta",
        "issuer": "Nosūtītājs",
        "number": "Numurs",
        "sender": "Piegādātājs",
        "takeout": "Izņemšana",
        "to_object": "Uz objektu"
      },
      "takeout_waybill_custom_item": {
        "amount": "Daudzums",
        "item_setting": "Vienības iestatījumi",
        "name": "Nosaukums",
        "package_name": "Iepakojums",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "weight": "Svars"
      },
      "vehicle": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      }
    },
    "created_at": "Izveidots",
    "errors": {
      "messages": {
        "record_invalid": "Pārbaude neizdevās: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Nevar dzēst ierakstu, jo ir atkarīgi %{record}",
          "has_one": "Nevar dzēst ierakstu, jo ir atkarīgs %{record}"
        }
      }
    },
    "models": {
      "destination": "Darba Objekts",
      "employee": "Darbinieks",
      "explosive": "Sprāgstviela",
      "item_setting": "Vienības iestatījums",
      "log": "Darbību žurnāls",
      "pdf_code_printout": "PDF kodu izdruka",
      "report": "Atskaite",
      "takeout": "Sprāgstvielu izņemšana",
      "takeout_waybill": "Pavadzīme",
      "takeout_waybill_custom_item": "Manuāli ievadāmā vienība",
      "vehicle": "Transports"
    },
    "removed": "Dzēsts",
    "updated_at": "Labots"
  },
  "back": "Atpakaļ",
  "cancel": "Atcelt",
  "date": {
    "abbr_day_names": [
      "Sv.",
      "P.",
      "O.",
      "T.",
      "C.",
      "Pk.",
      "S."
    ],
    "abbr_month_names": [
      null,
      "Janv",
      "Febr",
      "Marts",
      "Apr",
      "Maijs",
      "Jūn",
      "Jūl",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "svētdiena",
      "pirmdiena",
      "otrdiena",
      "trešdiena",
      "ceturtdiena",
      "piektdiena",
      "sestdiena"
    ],
    "formats": {
      "default": "%d.%m.%Y.",
      "long": "%Y. gada %e. %B",
      "short": "%e. %B"
    },
    "month_names": [
      null,
      "janvārī",
      "februārī",
      "martā",
      "aprīlī",
      "maijā",
      "jūnijā",
      "jūlijā",
      "augustā",
      "septembrī",
      "oktobrī",
      "novembrī",
      "decembrī"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "apmēram %{count} stunda",
        "other": "apmēram %{count} stundas",
        "zero": "apmēram %{count} stundas"
      },
      "about_x_months": {
        "one": "apmēram %{count} mēnesis",
        "other": "apmēram %{count} mēneši",
        "zero": "apmēram %{count} mēneši"
      },
      "about_x_years": {
        "one": "apmēram %{count} gads",
        "other": "apmēram %{count} gadi",
        "zero": "apmēram %{count} gadi"
      },
      "almost_x_years": {
        "one": "gandrīz %{count} gads",
        "other": "gandrīz %{count} gadi",
        "zero": "gandrīz %{count} gadi"
      },
      "half_a_minute": "pusminūte",
      "less_than_x_minutes": {
        "one": "mazāk par %{count} minūti",
        "other": "mazāk par %{count} minūtēm",
        "zero": "mazāk par %{count} minūtēm"
      },
      "less_than_x_seconds": {
        "one": "mazāk par %{count} sekundi",
        "other": "mazāk par %{count} sekundēm",
        "zero": "mazāk par %{count} sekundēm"
      },
      "over_x_years": {
        "one": "vairāk kā %{count} gads",
        "other": "vairāk kā %{count} gadi",
        "zero": "vairāk kā %{count} gadi"
      },
      "x_days": {
        "one": "%{count} diena",
        "other": "%{count} dienas",
        "zero": "%{count} dienas"
      },
      "x_minutes": {
        "one": "%{count} minūte",
        "other": "%{count} minūtes",
        "zero": "%{count} minūtes"
      },
      "x_months": {
        "one": "%{count} mēnesis",
        "other": "%{count} mēneši",
        "zero": "%{count} mēneši"
      },
      "x_seconds": {
        "one": "%{count} sekunde",
        "other": "%{count} sekundes",
        "zero": "%{count} sekundes"
      }
    },
    "prompts": {
      "day": "diena",
      "hour": "stunda",
      "minute": "minūte",
      "month": "mēnesis",
      "second": "sekunde",
      "year": "gads"
    }
  },
  "delete": "Dzēst",
  "destinations": {
    "edit": {
      "title": "Objekta rediģēšana"
    },
    "form": {
      "adress_note": "Adresei jābūt unikālai.",
      "adress_placeholder": "Objekta adrese",
      "name_placeholder": "Objekta nosaukums"
    },
    "new": {
      "title": "Jauna objekta pievienošana"
    }
  },
  "edit": "Rediģēt",
  "edit_object": "Rediģēt",
  "employees": {
    "edit": {
      "title": "Darbinieka rediģēšana"
    },
    "form": {
      "email_note": "Tam jābūt @gmail.com vai @balrock.lv epastam.",
      "email_placeholder": "Ievadi darbinieka epastu",
      "first_name_note": "Vārds nderīkst saturēt ciparus.",
      "first_name_placeholder": "Ievadi darbinieka vārdu",
      "last_name_note": "Uzvārds nedrīkst saturēt ciparus.",
      "last_name_placeholder": "Ievadi darbinieka uzvārdu"
    },
    "index": {
      "subtitle": "Darbineku un piekļuves pāŗvaldība",
      "title": "Darbinieki"
    },
    "new": {
      "title": "Jauna darbinieka pievienošana"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "ir jāpiekrīt",
      "blank": "ir jābūt aizpildītam",
      "confirmation": "nesakrīt ar apstiprinājumu",
      "empty": "ir jābūt aizpildītam",
      "equal_to": "ir jābūt vienādam ar %{count}",
      "even": "ir jābūt pāra skaitlim",
      "exclusion": "nav pieejams",
      "greater_than": "ir jābūt lielākam par %{count}",
      "greater_than_or_equal_to": "ir jābūt lielākam vai vienādam ar %{count}",
      "inclusion": "nav iekļauts sarakstā",
      "invalid": "nav derīgs",
      "less_than": "ir jābūt mazākam par %{count}",
      "less_than_or_equal_to": "ir jābūt mazākam vai vienādam ar %{count}",
      "model_invalid": "Validācija neizdevās: %{errors}",
      "not_a_number": "nav skaitlis",
      "not_an_integer": "ir jābūt veselam skaitlim",
      "odd": "ir jābūt nepāra skaitlim",
      "other_than": "jābūt citam nekā %{count}",
      "present": "jābūt tukšam",
      "required": "ir jābūt",
      "taken": "ir jau aizņemts",
      "too_long": {
        "one": "ir par garu (maksimums ir %{count} simbols)",
        "other": "ir par garu (maksimums ir %{count} simboli)",
        "zero": "ir par garu (maksimums ir %{count} simboli)"
      },
      "too_short": {
        "one": "ir par īsu (minimums ir %{count} simbols)",
        "other": "ir par īsu (minimums ir %{count} simboli)",
        "zero": "ir par īsu (minimums ir %{count} simboli)"
      },
      "wrong_length": {
        "one": "ir nepareizs garums (jābūt %{count} simbolam)",
        "other": "ir nepareizs garums (jābūt %{count} simboliem)",
        "zero": "ir nepareizs garums (jābūt %{count} simboliem)"
      }
    },
    "template": {
      "body": "Problēmas ir šajos ievades laukos:",
      "header": {
        "one": "Dēļ %{count} kļūdas šis %{model} netika saglabāts",
        "other": "Dēļ %{count} kļūdām šis %{model} netika saglabāts",
        "zero": "Dēļ %{count} kļūdām šis %{model} netika saglabāts"
      }
    }
  },
  "explosives": {
    "amount_correction": {
      "no_camera_alert": "Kamera nav pieejama!",
      "no_camera_alert_message": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "subtitle": "Noskenē kodu vai ievadi numuru un izvēlies turpmāko darbību",
      "title": "Sprāgstvielu apjoma korekcija"
    },
    "correction_code_scanner": {
      "camera_alert": "Kamera nav pieejama!",
      "correction_modal_title": "Apjoma korekcija",
      "explosive_type": "Sprāgstviela",
      "explosive_units": "vienības",
      "no_camera_permission": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "remove_explosives": "Dzēst no noliktavas",
      "revive_explosives": "Atgriezt noliktavā",
      "some_units_deleted": "bet dažas no tajā esošajām vienībām jau ir dzēstas."
    },
    "file_upload": {
      "files_selected": " faili izvēlēti",
      "import_notification": "Dati tiek importēti, lūdzu uzgaidiet."
    },
    "index": {
      "title": "Kļūdainie ieraksti"
    },
    "new": {
      "import": "Importēt",
      "upload_note": "Izvēlies 1 vai vairākus .xml vai .xlsx failu."
    }
  },
  "helpers": {
    "select": {
      "prompt": "Lūdzu izvēlies"
    },
    "submit": {
      "create": "Izveidot %{model}",
      "submit": "Saglabāt %{model}",
      "update": "Atjaunināt %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007ff32a3b8420 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/pluralization.rb:85 (lambda)>"
    }
  },
  "item_settings": {
    "index": {
      "subtitle": "Vienību svara un pavadzīmju iestatījumi",
      "title": "Vienību iestatījumi"
    },
    "item_setting_row": {
      "weight_note": "Noklusējuma svars"
    }
  },
  "logs": {
    "corrected_explosives": {
      "corrected_amount": "Laboto vienību skaits:",
      "corrected_explosives_table": "Koriģētās sprāgstvielas",
      "correction_action": "Korekcijas darbība:",
      "correction_date": "Datums:",
      "deletion": "❌ Dzēšana",
      "employee": "Korekciju veicējs:",
      "return": "✅ Atgriešana noliktavā",
      "title": "Sprāgstvielu korekcijas pārskats"
    },
    "helper_row": {
      "changes": "Izmaiņas",
      "corrected_explosives_count": "Koriģēto sprāgstvielu skaits",
      "correction_action": "Korekcijas darbība",
      "deletion": "Dzēšana",
      "edited": "Rediģēts",
      "edited_object": "Rediģētais",
      "error": "Kaut kas nogāja greizi",
      "imported_explosives_count": "Pievienoto sprāgstvielu skaits",
      "precise_time": "Precīzs laiks",
      "return": "Atgriešana noliktavā",
      "returned_explosives_count": "Atgriezto sprāgstvielu skaits",
      "taken_explosives_count": "Izņemto sprāgstvielu skaits",
      "takeout_responsible_employee": "Izņemšanas atbildīgais (izveidotājs)",
      "to_overview": "Uz pārskatu"
    },
    "index": {
      "filtering": "Filtrēšana",
      "subtitle": "Notikumi ir sakārtoti dilstošā secībā pēc to vecuma",
      "title": "Darbību žurnāls"
    },
    "log_querry": {
      "clear_filters": "Dzēst filtrus",
      "filter": "Filtrēt"
    },
    "log_table": {
      "no_results": "Pēc dotajiem kritērijiem nekas netika atrasts"
    }
  },
  "main": {
    "index": {
      "active_takeouts": "Aktīvās sprāgstvielu izņemšanas:",
      "current_employee": "Lietotājs:",
      "employee_admin": "Administrators",
      "employee_regular": "Darbinieks",
      "employee_role": "Statuss:",
      "explosives_in_warehouse": "Sprāgstvielu skaits noliktavā:",
      "no_actions": "Tev vēl nav veiktu darbību.",
      "previous_actions": "Tavas pēdējās darbības: ",
      "title": "Balrock sprāgstvielu noliktavas uzskaite"
    }
  },
  "new_object": "Pievienot",
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": {
            "one": "miljards",
            "other": "miljardi",
            "zero": "miljardi"
          },
          "million": {
            "one": "miljons",
            "other": "miljoni",
            "zero": "miljoni"
          },
          "quadrillion": {
            "one": "kvadriljons",
            "other": "kvadriljoni",
            "zero": "kvadriljoni"
          },
          "thousand": {
            "one": "tūkstotis",
            "other": "tūkstoši",
            "zero": "tūkstoši"
          },
          "trillion": {
            "one": "triljons",
            "other": "triljoni",
            "zero": "triljoni"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "baits",
            "other": "baiti",
            "zero": "baiti"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "%{number}.",
      "ordinals": "."
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pagy": {
    "combo_nav_js": "Lapa %{page_input} no %{pages}",
    "info": {
      "multiple_pages": "Tiek parādītas %{item_name} <b>%{from}-%{to}</b> of <b>%{count}</b> in total",
      "no_items": "%{item_name} netika atrasts/a",
      "single_page": "Tiek parādīta <b>%{count}</b> %{item_name}"
    },
    "item_name": {
      "one": "Ieraksts",
      "other": "Ieraksti"
    },
    "items_selector_js": "Lapā iekļaut %{items_input} %{item_name} ierakstus",
    "nav": {
      "gap": "&hellip;",
      "next": "Nākamā&nbsp;&rsaquo;",
      "prev": "&lsaquo;&nbsp;Iepriekšējā"
    }
  },
  "pdf_code_printouts": {
    "index": {
      "no_results": "Nekas netika atrasts",
      "package_search": "Drukājamo vienību kastu meklēšana pēc nosaukuma",
      "package_search_placeholder": "Nosaukums vai tā daļa",
      "search": "Meklēt",
      "title": "Vienību kodu duplikātu printēšana"
    },
    "table_row": {
      "get_pdf": "Iegūt PDF kodu lapu"
    }
  },
  "reports": {
    "available_explosives": {
      "headers": [
        "Kods",
        "Ražotājs",
        "Nosaukums",
        "Kastes numurs",
        "Iepakojuma numurs",
        "Vienības numurs",
        "Importa fails",
        "Atrašanās vieta",
        "Atbildīgais"
      ],
      "no_employee": "Vienībai nav atbildīgā",
      "report_row_title": "Pieejamo sprāgstvielu pārskats %{date}",
      "worksheet_title": "Pieejamās sprāgstvielas"
    },
    "explosive_movement": {
      "sheet_name": {
        "active": "AktīvaIzņemšana-%{index}-%{date}",
        "returned": "AtgrieztaIzņemšana-%{index}-%{date}"
      },
      "states": {
        "active": "izņemta",
        "returned": "atgriezta",
        "used": "izlietota"
      },
      "takeout_explosives_headers": [
        "Kods",
        "Nosaukums",
        "Kaste",
        "Iepakojums",
        "Sprāgstviela",
        "Importa fails",
        "Statuss"
      ],
      "takeout_info_headers": [
        "Darbinieks",
        "Darba objekts",
        "Transportlīdzeklis",
        "Statuss",
        "Izņemto vienību skaits",
        "Izlietoto vienību skaits"
      ],
      "worksheet_title": "Sprāgstvielu izņemšanas/atgriešanas pārskats"
    },
    "index": {
      "available_explosives_count": "Pašlaik pieejamas %{count} vienības",
      "available_explosives_report": "Pieejamās sprāgstvielas",
      "date_range": "Laika periods",
      "explosive_movement_report": "Sprāgstvielu kustība laika periodā",
      "explosive_summary": "Sprāgstvielu atlikumi noliktavā pēc nosaukuma",
      "explosives_in_warehouse": "Noliktavā atrodas šādas vienības:",
      "prepare_report": "Sagatavot",
      "title": "Pieejamās sistēmas atskaites .xlsx formātā",
      "total_amount": "Kopā:"
    }
  },
  "save": "Saglabāt",
  "sessions": {
    "new": {
      "google_auth": "Pieslēgties ar Google",
      "title": "Pieslēgties noliktavas uzskaitei"
    }
  },
  "state": "Ieslēgts",
  "support": {
    "array": {
      "last_word_connector": " un ",
      "two_words_connector": " un ",
      "words_connector": ", "
    }
  },
  "takeout_waybills": {
    "edit": {
      "manual_items": "Manuāli ievadāmās vienības",
      "title": "Pavadzīmes %{number} rediģēšana",
      "view_waybill": "Skatīt pavadzīmi",
      "waybill_persons": "Pavadzīmes personas"
    },
    "index": {
      "subtitle": "Visu izveidoto pavadzīmju saraksts",
      "title": "Pavadzīmes"
    }
  },
  "takeouts": {
    "index": null
  },
  "time": {
    "am": "priekšpusdiena",
    "formats": {
      "default": "%Y. gada %e. %B, %H:%M",
      "long": "%Y. gada %e. %B, %H:%M:%S",
      "short": "%d.%m.%Y., %H:%M"
    },
    "pm": "pēcpusdiena"
  },
  "view": "Skatīt"
});
I18n.translations["ru"] = I18n.extend((I18n.translations["ru"] || {}), {
  "actions": "Darbības",
  "activerecord": {
    "attributes": {
      "destination": {
        "adress": "Adrese",
        "name": "Nosaukums"
      },
      "employee": {
        "active": "Aktīvs",
        "admin": "Administrators",
        "email": "E-pasts",
        "first_name": "Vārds",
        "last_name": "Uzvārds"
      },
      "explosive": {
        "available": "Pieejama",
        "box_number": "Kastes numurs",
        "code": "Vienības kods",
        "delivery_date": "Piegādes datums",
        "import_name": "Importa faila nosaukums",
        "item_number": "Vienības numurs",
        "manufacturer": "Ražotājs",
        "middlebox_number": "Iepakojuma numurs",
        "product_name": "Produkta nosaukums",
        "product_number": "Produkta numurs",
        "reason": "Iemesls",
        "takeout": "Izņemšana"
      },
      "item_setting": {
        "include_in_waybills": "Iekļaut pavadzīmēs",
        "item_count": "Vienību skaits",
        "item_name_display": "Nosaukums displejā",
        "item_name_import": "Vienības nosaukums",
        "manually_created": "Manuāli izveidots",
        "set_weight_manually": "Manuāls svars",
        "waybill_position": "Pozīcija pavadzīmē",
        "weight": "Svars"
      },
      "log": {
        "action": "Darbība",
        "author": "Veicējs",
        "corrected_explosives": "Labotās sprāgstvielas",
        "date": "Datums",
        "destination": "Darba objekts",
        "employee": "Darbinieks",
        "id_number": "ID numurs",
        "import_name": "Importa fails",
        "notes": "Piezīmes",
        "takeout": "Izņemšana",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "takeout_waybill_custom_items": "Izņemšanas pavadzīmes manuālā rinda",
        "vehicle": "Transportlīdzeklis"
      },
      "pdf_code_printout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "report": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      },
      "takeout_waybill": {
        "Receiver": "Saņēmējs",
        "date": "Datums",
        "destination": "Objekts",
        "direction": "Virziens",
        "employee": "Darbinieks",
        "initally_edited": "Rediģēta",
        "issuer": "Nosūtītājs",
        "number": "Numurs",
        "sender": "Piegādātājs",
        "takeout": "Izņemšana",
        "to_object": "Uz objektu"
      },
      "takeout_waybill_custom_item": {
        "amount": "Daudzums",
        "item_setting": "Vienības iestatījumi",
        "name": "Nosaukums",
        "package_name": "Iepakojums",
        "takeout_waybill": "Izņemšanas pavadzīme",
        "weight": "Svars"
      },
      "vehicle": {
        "code": "Kods",
        "name": "Nosaukums",
        "unit": "Mērvienība",
        "weight": "Svars"
      }
    },
    "created_at": "Izveidots",
    "errors": {
      "messages": {
        "record_invalid": "Pārbaude neizdevās: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Nevar dzēst ierakstu, jo ir atkarīgi %{record}",
          "has_one": "Nevar dzēst ierakstu, jo ir atkarīgs %{record}"
        }
      }
    },
    "models": {
      "destination": "Darba Objekts",
      "employee": "Darbinieks",
      "explosive": "Sprāgstviela",
      "item_setting": "Vienības iestatījums",
      "log": "Darbību žurnāls",
      "pdf_code_printout": "PDF kodu izdruka",
      "report": "Atskaite",
      "takeout": "Sprāgstvielu izņemšana",
      "takeout_waybill": "Pavadzīme",
      "takeout_waybill_custom_item": "Manuāli ievadāmā vienība",
      "vehicle": "Transports"
    },
    "removed": "Dzēsts",
    "updated_at": "Labots"
  },
  "back": "Atpakaļ",
  "cancel": "Atcelt",
  "date": {
    "abbr_day_names": [
      "Sv.",
      "P.",
      "O.",
      "T.",
      "C.",
      "Pk.",
      "S."
    ],
    "abbr_month_names": [
      null,
      "Janv",
      "Febr",
      "Marts",
      "Apr",
      "Maijs",
      "Jūn",
      "Jūl",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "svētdiena",
      "pirmdiena",
      "otrdiena",
      "trešdiena",
      "ceturtdiena",
      "piektdiena",
      "sestdiena"
    ],
    "formats": {
      "default": "%d.%m.%Y.",
      "long": "%Y. gada %e. %B",
      "short": "%e. %B"
    },
    "month_names": [
      null,
      "janvārī",
      "februārī",
      "martā",
      "aprīlī",
      "maijā",
      "jūnijā",
      "jūlijā",
      "augustā",
      "septembrī",
      "oktobrī",
      "novembrī",
      "decembrī"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "apmēram %{count} stunda",
        "other": "apmēram %{count} stundas",
        "zero": "apmēram %{count} stundas"
      },
      "about_x_months": {
        "one": "apmēram %{count} mēnesis",
        "other": "apmēram %{count} mēneši",
        "zero": "apmēram %{count} mēneši"
      },
      "about_x_years": {
        "one": "apmēram %{count} gads",
        "other": "apmēram %{count} gadi",
        "zero": "apmēram %{count} gadi"
      },
      "almost_x_years": {
        "one": "gandrīz %{count} gads",
        "other": "gandrīz %{count} gadi",
        "zero": "gandrīz %{count} gadi"
      },
      "half_a_minute": "pusminūte",
      "less_than_x_minutes": {
        "one": "mazāk par %{count} minūti",
        "other": "mazāk par %{count} minūtēm",
        "zero": "mazāk par %{count} minūtēm"
      },
      "less_than_x_seconds": {
        "one": "mazāk par %{count} sekundi",
        "other": "mazāk par %{count} sekundēm",
        "zero": "mazāk par %{count} sekundēm"
      },
      "over_x_years": {
        "one": "vairāk kā %{count} gads",
        "other": "vairāk kā %{count} gadi",
        "zero": "vairāk kā %{count} gadi"
      },
      "x_days": {
        "one": "%{count} diena",
        "other": "%{count} dienas",
        "zero": "%{count} dienas"
      },
      "x_minutes": {
        "one": "%{count} minūte",
        "other": "%{count} minūtes",
        "zero": "%{count} minūtes"
      },
      "x_months": {
        "one": "%{count} mēnesis",
        "other": "%{count} mēneši",
        "zero": "%{count} mēneši"
      },
      "x_seconds": {
        "one": "%{count} sekunde",
        "other": "%{count} sekundes",
        "zero": "%{count} sekundes"
      }
    },
    "prompts": {
      "day": "diena",
      "hour": "stunda",
      "minute": "minūte",
      "month": "mēnesis",
      "second": "sekunde",
      "year": "gads"
    }
  },
  "delete": "Dzēst",
  "destinations": {
    "edit": {
      "title": "Objekta rediģēšana"
    },
    "form": {
      "adress_note": "Adresei jābūt unikālai.",
      "adress_placeholder": "Objekta adrese",
      "name_placeholder": "Objekta nosaukums"
    },
    "new": {
      "title": "Jauna objekta pievienošana"
    }
  },
  "edit": "Rediģēt",
  "edit_object": "Rediģēt",
  "employees": {
    "edit": {
      "title": "Darbinieka rediģēšana"
    },
    "form": {
      "email_note": "Tam jābūt @gmail.com vai @balrock.lv epastam.",
      "email_placeholder": "Ievadi darbinieka epastu",
      "first_name_note": "Vārds nderīkst saturēt ciparus.",
      "first_name_placeholder": "Ievadi darbinieka vārdu",
      "last_name_note": "Uzvārds nedrīkst saturēt ciparus.",
      "last_name_placeholder": "Ievadi darbinieka uzvārdu"
    },
    "index": {
      "subtitle": "Darbineku un piekļuves pāŗvaldība",
      "title": "Darbinieki"
    },
    "new": {
      "title": "Jauna darbinieka pievienošana"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "ir jāpiekrīt",
      "blank": "ir jābūt aizpildītam",
      "confirmation": "nesakrīt ar apstiprinājumu",
      "empty": "ir jābūt aizpildītam",
      "equal_to": "ir jābūt vienādam ar %{count}",
      "even": "ir jābūt pāra skaitlim",
      "exclusion": "nav pieejams",
      "greater_than": "ir jābūt lielākam par %{count}",
      "greater_than_or_equal_to": "ir jābūt lielākam vai vienādam ar %{count}",
      "inclusion": "nav iekļauts sarakstā",
      "invalid": "nav derīgs",
      "less_than": "ir jābūt mazākam par %{count}",
      "less_than_or_equal_to": "ir jābūt mazākam vai vienādam ar %{count}",
      "model_invalid": "Validācija neizdevās: %{errors}",
      "not_a_number": "nav skaitlis",
      "not_an_integer": "ir jābūt veselam skaitlim",
      "odd": "ir jābūt nepāra skaitlim",
      "other_than": "jābūt citam nekā %{count}",
      "present": "jābūt tukšam",
      "required": "ir jābūt",
      "taken": "ir jau aizņemts",
      "too_long": {
        "one": "ir par garu (maksimums ir %{count} simbols)",
        "other": "ir par garu (maksimums ir %{count} simboli)",
        "zero": "ir par garu (maksimums ir %{count} simboli)"
      },
      "too_short": {
        "one": "ir par īsu (minimums ir %{count} simbols)",
        "other": "ir par īsu (minimums ir %{count} simboli)",
        "zero": "ir par īsu (minimums ir %{count} simboli)"
      },
      "wrong_length": {
        "one": "ir nepareizs garums (jābūt %{count} simbolam)",
        "other": "ir nepareizs garums (jābūt %{count} simboliem)",
        "zero": "ir nepareizs garums (jābūt %{count} simboliem)"
      }
    },
    "template": {
      "body": "Problēmas ir šajos ievades laukos:",
      "header": {
        "one": "Dēļ %{count} kļūdas šis %{model} netika saglabāts",
        "other": "Dēļ %{count} kļūdām šis %{model} netika saglabāts",
        "zero": "Dēļ %{count} kļūdām šis %{model} netika saglabāts"
      }
    }
  },
  "explosives": {
    "amount_correction": {
      "no_camera_alert": "Kamera nav pieejama!",
      "no_camera_alert_message": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "subtitle": "Noskenē kodu vai ievadi numuru un izvēlies turpmāko darbību",
      "title": "Sprāgstvielu apjoma korekcija"
    },
    "correction_code_scanner": {
      "camera_alert": "Kamera nav pieejama!",
      "correction_modal_title": "Apjoma korekcija",
      "explosive_type": "Sprāgstviela",
      "explosive_units": "vienības",
      "no_camera_permission": "Iespējams neesi devis atļauju tās piekļuvei, vai nav ieslēgts javascript.",
      "remove_explosives": "Dzēst no noliktavas",
      "revive_explosives": "Atgriezt noliktavā",
      "some_units_deleted": "bet dažas no tajā esošajām vienībām jau ir dzēstas."
    },
    "file_upload": {
      "files_selected": " faili izvēlēti",
      "import_notification": "Dati tiek importēti, lūdzu uzgaidiet."
    },
    "index": {
      "title": "Kļūdainie ieraksti"
    },
    "new": {
      "import": "Importēt",
      "upload_note": "Izvēlies 1 vai vairākus .xml vai .xlsx failu."
    }
  },
  "helpers": {
    "select": {
      "prompt": "Lūdzu izvēlies"
    },
    "submit": {
      "create": "Izveidot %{model}",
      "submit": "Saglabāt %{model}",
      "update": "Atjaunināt %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007ff32a3b8420 /usr/local/bundle/gems/rails-i18n-7.0.10/lib/rails_i18n/pluralization.rb:85 (lambda)>"
    }
  },
  "item_settings": {
    "index": {
      "subtitle": "Vienību svara un pavadzīmju iestatījumi",
      "title": "Vienību iestatījumi"
    },
    "item_setting_row": {
      "weight_note": "Noklusējuma svars"
    }
  },
  "logs": {
    "corrected_explosives": {
      "corrected_amount": "Laboto vienību skaits:",
      "corrected_explosives_table": "Koriģētās sprāgstvielas",
      "correction_action": "Korekcijas darbība:",
      "correction_date": "Datums:",
      "deletion": "❌ Dzēšana",
      "employee": "Korekciju veicējs:",
      "return": "✅ Atgriešana noliktavā",
      "title": "Sprāgstvielu korekcijas pārskats"
    },
    "helper_row": {
      "changes": "Izmaiņas",
      "corrected_explosives_count": "Koriģēto sprāgstvielu skaits",
      "correction_action": "Korekcijas darbība",
      "deletion": "Dzēšana",
      "edited": "Rediģēts",
      "edited_object": "Rediģētais",
      "error": "Kaut kas nogāja greizi",
      "imported_explosives_count": "Pievienoto sprāgstvielu skaits",
      "precise_time": "Precīzs laiks",
      "return": "Atgriešana noliktavā",
      "returned_explosives_count": "Atgriezto sprāgstvielu skaits",
      "taken_explosives_count": "Izņemto sprāgstvielu skaits",
      "takeout_responsible_employee": "Izņemšanas atbildīgais (izveidotājs)",
      "to_overview": "Uz pārskatu"
    },
    "index": {
      "filtering": "Filtrēšana",
      "subtitle": "Notikumi ir sakārtoti dilstošā secībā pēc to vecuma",
      "title": "Darbību žurnāls"
    },
    "log_querry": {
      "clear_filters": "Dzēst filtrus",
      "filter": "Filtrēt"
    },
    "log_table": {
      "no_results": "Pēc dotajiem kritērijiem nekas netika atrasts"
    }
  },
  "main": {
    "index": {
      "active_takeouts": "Aktīvās sprāgstvielu izņemšanas:",
      "current_employee": "Lietotājs:",
      "employee_admin": "Administrators",
      "employee_regular": "Darbinieks",
      "employee_role": "Statuss:",
      "explosives_in_warehouse": "Sprāgstvielu skaits noliktavā:",
      "no_actions": "Tev vēl nav veiktu darbību.",
      "previous_actions": "Tavas pēdējās darbības: ",
      "title": "Balrock sprāgstvielu noliktavas uzskaite"
    }
  },
  "new_object": "Pievienot",
  "number": {
    "currency": {
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": {
            "one": "miljards",
            "other": "miljardi",
            "zero": "miljardi"
          },
          "million": {
            "one": "miljons",
            "other": "miljoni",
            "zero": "miljoni"
          },
          "quadrillion": {
            "one": "kvadriljons",
            "other": "kvadriljoni",
            "zero": "kvadriljoni"
          },
          "thousand": {
            "one": "tūkstotis",
            "other": "tūkstoši",
            "zero": "tūkstoši"
          },
          "trillion": {
            "one": "triljons",
            "other": "triljoni",
            "zero": "triljoni"
          },
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 1,
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "baits",
            "other": "baiti",
            "zero": "baiti"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "%{number}.",
      "ordinals": "."
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pagy": {
    "combo_nav_js": "Lapa %{page_input} no %{pages}",
    "info": {
      "multiple_pages": "Tiek parādītas %{item_name} <b>%{from}-%{to}</b> of <b>%{count}</b> in total",
      "no_items": "%{item_name} netika atrasts/a",
      "single_page": "Tiek parādīta <b>%{count}</b> %{item_name}"
    },
    "item_name": {
      "one": "Ieraksts",
      "other": "Ieraksti"
    },
    "items_selector_js": "Lapā iekļaut %{items_input} %{item_name} ierakstus",
    "nav": {
      "gap": "&hellip;",
      "next": "Nākamā&nbsp;&rsaquo;",
      "prev": "&lsaquo;&nbsp;Iepriekšējā"
    }
  },
  "pdf_code_printouts": {
    "index": {
      "no_results": "Nekas netika atrasts",
      "package_search": "Drukājamo vienību kastu meklēšana pēc nosaukuma",
      "package_search_placeholder": "Nosaukums vai tā daļa",
      "search": "Meklēt",
      "title": "Vienību kodu duplikātu printēšana"
    },
    "table_row": {
      "get_pdf": "Iegūt PDF kodu lapu"
    }
  },
  "reports": {
    "available_explosives": {
      "headers": [
        "Kods",
        "Ražotājs",
        "Nosaukums",
        "Kastes numurs",
        "Iepakojuma numurs",
        "Vienības numurs",
        "Importa fails",
        "Atrašanās vieta",
        "Atbildīgais"
      ],
      "no_employee": "Vienībai nav atbildīgā",
      "report_row_title": "Pieejamo sprāgstvielu pārskats %{date}",
      "worksheet_title": "Pieejamās sprāgstvielas"
    },
    "explosive_movement": {
      "sheet_name": {
        "active": "AktīvaIzņemšana-%{index}-%{date}",
        "returned": "AtgrieztaIzņemšana-%{index}-%{date}"
      },
      "states": {
        "active": "izņemta",
        "returned": "atgriezta",
        "used": "izlietota"
      },
      "takeout_explosives_headers": [
        "Kods",
        "Nosaukums",
        "Kaste",
        "Iepakojums",
        "Sprāgstviela",
        "Importa fails",
        "Statuss"
      ],
      "takeout_info_headers": [
        "Darbinieks",
        "Darba objekts",
        "Transportlīdzeklis",
        "Statuss",
        "Izņemto vienību skaits",
        "Izlietoto vienību skaits"
      ],
      "worksheet_title": "Sprāgstvielu izņemšanas/atgriešanas pārskats"
    },
    "index": {
      "available_explosives_count": "Pašlaik pieejamas %{count} vienības",
      "available_explosives_report": "Pieejamās sprāgstvielas",
      "date_range": "Laika periods",
      "explosive_movement_report": "Sprāgstvielu kustība laika periodā",
      "explosive_summary": "Sprāgstvielu atlikumi noliktavā pēc nosaukuma",
      "explosives_in_warehouse": "Noliktavā atrodas šādas vienības:",
      "prepare_report": "Sagatavot",
      "title": "Pieejamās sistēmas atskaites .xlsx formātā",
      "total_amount": "Kopā:"
    }
  },
  "save": "Saglabāt",
  "sessions": {
    "new": {
      "google_auth": "Pieslēgties ar Google",
      "title": "Pieslēgties noliktavas uzskaitei"
    }
  },
  "state": "Ieslēgts",
  "support": {
    "array": {
      "last_word_connector": " un ",
      "two_words_connector": " un ",
      "words_connector": ", "
    }
  },
  "takeout_waybills": {
    "edit": {
      "manual_items": "Manuāli ievadāmās vienības",
      "title": "Pavadzīmes %{number} rediģēšana",
      "view_waybill": "Skatīt pavadzīmi",
      "waybill_persons": "Pavadzīmes personas"
    },
    "index": {
      "subtitle": "Visu izveidoto pavadzīmju saraksts",
      "title": "Pavadzīmes"
    }
  },
  "takeouts": {
    "index": null
  },
  "time": {
    "am": "priekšpusdiena",
    "formats": {
      "default": "%Y. gada %e. %B, %H:%M",
      "long": "%Y. gada %e. %B, %H:%M:%S",
      "short": "%d.%m.%Y., %H:%M"
    },
    "pm": "pēcpusdiena"
  },
  "view": "Skatīt"
});
